import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const plateCanPark = createAsyncThunk(
   'information/information/plateCanPark',
   async (tabletPermit, { dispatch }) => {
      const response = await axios.post(`/PlateCanPark`, tabletPermit).catch(x => x.response);
      if (response.status === 200) {
         await dispatch(checkDmr(tabletPermit.licensePlate))
         dispatch(setInformation({ licensePlate: tabletPermit.licensePlate, phoneNumber: tabletPermit.phoneNumber }))
      }
      const data = await response.data;
      return data;
   }
);

export const voucher = createAsyncThunk(
   'information/information/voucher',
   async ({ email, licensePlate, voucherCode, groupId }, { dispatch }) => {
      const response = await axios.post(
         `/Voucher?email=${email}&licensePlate=${licensePlate}&voucherCode=${voucherCode}`
      );
      if (response.status === 200) {
         dispatch(setInformation({ licensePlate: licensePlate, phoneNumber: email }));
      }
      const data = await response.data;
      return { data };
   }
);

export const tabletInfo = createAsyncThunk('information/information/tablet', async uid => {
   const response = await axios.get(`/Tablet/${uid}/info`);
   const data = await response.data;
   return { data, uid };
});

export const parkFromTablet = createAsyncThunk('information/information/parkFromTablet', async permit => {
   const response = await axios.post(`/Tablet`, permit).catch(x => x.response);
   const data = await response.data;
   return { data };
});

export const checkDmr = createAsyncThunk('information/information/checkDmr', async licensePlate => {
   const response = await axios.get(`https://dmr.mobilparkering.dk/v1/vehicleregistration/${licensePlate}/public`);
   const data = await response.data;
   return data;
});

function getDuration(minutes) {
   return minutes;
}

function voucherDuration(start, end) {
   let starts = new Date();
   starts = Date.parse(start);

   let ends = new Date();
   ends = Date.parse(end);

   var duration = (ends - starts) / 60000;

   return duration;
}

export const ConfirmParking = createAsyncThunk(
   'information/information/confirmParking',
   async ({
      email,
      PhoneNumber,
      VehicleRegistrationCountry,
      Duration,
      VehicleRegistration, 
      parkingAreas,
      StartTime,
      EndTime,
      UId,
      Lang,
   }) => {
      var body = {
         email,
         PhoneNumber,
         VehicleRegistrationCountry,
         Duration,
         VehicleRegistration,
         parkingAreas,
         StartTime,
         EndTime,
         UId,
         Lang,
      };
      const response = await axios.post(`/Tablet/confirm`, body);
      const data = await response.data;
      return data;
   }
);

export const ConfirmVoucherParking = createAsyncThunk(
   'information/information/confirmVoucherParking',
   async (
      {
         email,
         PhoneNumber,
         VehicleRegistrationCountry,
         Duration,
         AllAreas,
         VehicleRegistration,
         VoucherCode,
         parkingAreas,
         StartTime,
         EndTime,
         GroupId,
         Lang,
      },
      { dispatch }
   ) => {
      var body = {
         email,
         PhoneNumber,
         VehicleRegistrationCountry,
         VehicleRegistration,
         AllAreas,
         parkingAreas,
         VoucherCode,
         StartTime,
         EndTime,
         GroupId,
         Lang,
      };
      const response = await axios.post(`/Voucher/confirm`, body);
      const data = await response.data;
      return data;
   }
);

const informationAdapter = createEntityAdapter({});

const initialValues = {
   loading: 'idle',
   vehicle: {
      registrationNumber: '',
      brand: '',
      typeName: '',
      variant: '',
      fuel: '',
   },
   phoneNumber: '',
   parkingNumber: {},
   isVoucher: false,
   parking: {
      Uid: null,
      ParkingAreas: [],
      Duration: 0,
      ParkingId: '',
   },
   voucher: {
      AllAreas: false,
      Email: '',
      PhoneNumber: '',
      VoucherCode: '',
      GroupId: null,
      VehicleRegistration: '',
      StartTime: null,
      EndTime: null,
      ParkingAreas: [],
      Duration: 0,
      ParkingId: '',
   },
   period: {
      StartTime: '',
      EndTime: '',
      Duration: 0,
   },
};

export const { selectAll: selectInformation, selectById: selectInformationById } = informationAdapter.getSelectors(
   state => state.information.information
);

const informationSlice = createSlice({
   name: 'information/information',
   initialState: informationAdapter.getInitialState({
      loading: 'idle',
      vehicle: {
         registrationNumber: '',
         brand: '',
         typeName: '',
         variant: '',
         fuel: '',
      },
      phoneNumber: '',
      isVoucher: false,
      parkingNumber: {},
      parking: {
         Uid: null,
         ParkingAreas: [],
         Duration: 0,
         ParkingId: '',
      },
      voucher: {
         AllAreas: false,
         Email: '',
         PhoneNumber: '',
         VoucherCode: '',
         GroupId: null,
         VehicleRegistration: '',
         StartTime: null,
         EndTime: null,
         ParkingAreas: [],
         Duration: 0,
         ParkingId: '',
      },
      period: {
         StartTime: '',
         EndTime: '',
         Duration: 0,
      },
   }),
   reducers: {
      setInformation: {
         reducer: (state, action) => {
            state.vehicle.registrationNumber = action.payload.licensePlate.toUpperCase();
            state.phoneNumber = action.payload.phoneNumber;
         },
      },
      reset: () => initialValues,
   },
   extraReducers: {
      [checkDmr.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [checkDmr.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
            if (action.payload.errors) {
               state.errors = action.payload.errors;
            } else {
               state.vehicle = action.payload;
               state.success = true;
            }
         }
      },
      [checkDmr.rejected]: state => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
      [tabletInfo.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [tabletInfo.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';

            if (action.payload.errors) {
               state.errors = action.payload.errors;
            } else {
               state.parking = action.payload.data;
               state.isVoucher = false;

               state.success = true;
            }
         }
      },
      [tabletInfo.rejected]: state => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
      [parkFromTablet.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [parkFromTablet.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }

         if (action.payload.errors) {
            state.errors = action.payload.errors;
         } else {
            state.parking = action.payload.data;
            state.period.StartTime = action.payload.data.StartTime;
            state.period.EndTime = action.payload.data.EndTime;
            state.period.Duration = getDuration(action.payload.data.Duration);
            state.success = true;
         }
      },
      [parkFromTablet.rejected]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
      [plateCanPark.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [plateCanPark.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
            if (action.payload.errors) {
               state.errors = action.payload.errors;
            } else {
               state.success = true;
            }
         }
      },
      [plateCanPark.rejected]: state => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
      [ConfirmParking.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [ConfirmParking.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
         if (action.payload.errors) {
            state.errors = action.payload.errors;
         } else {
            state.parkingNumber = action.payload.number;
            state.success = true;
         }
      },
      [ConfirmParking.rejected]: state => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
      [ConfirmVoucherParking.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [ConfirmVoucherParking.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
         if (action.payload.errors) {
            state.errors = action.payload.errors;
         } else {
            state.parkingNumber = action.payload.number;
            state.success = true;
         }
      },
      [ConfirmVoucherParking.rejected]: state => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
      [voucher.pending]: state => {
         if (state.loading === 'idle') {
            state.loading = 'pending';
         }
      },
      [voucher.fulfilled]: (state, action) => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
            if (action.payload.errors) {
               state.errors = action.payload.errors;
            } else {
               state.isVoucher = true;
               state.voucher = action.payload.data;
               state.period.Duration = voucherDuration(action.payload.data.StartTime, action.payload.data.EndTime);
               state.success = true;
            }
         }
      },
      [voucher.rejected]: state => {
         if (state.loading === 'pending') {
            state.loading = 'idle';
         }
      },
   },
});
export const { setInformation, reset } = informationSlice.actions;

export default informationSlice.reducer;
